<template>
  <div class="px-5 py-6">
    <div class="b-bottom pb-1">
      <span>Add New Role</span>
    </div>
    <div class="my-3">
      <v-form v-model="valid" @submit.prevent="addNewRoleHandler">
        <base-input v-model="role.name" placeholder="Role Name"></base-input>

        <div class="divider-dark my-4"></div>
        <div class="d-flex align-center justify-space-between">
          <v-btn elevation="1" @click="$emit('cancel')"
            ><span class="font-12">Cancel</span></v-btn
          >
          <v-btn
            elevation="1"
            color="darkblue"
            :dark="valid"
            @click="submitHandler"
            :disabled="!valid"
            type="submit"
            ><span class="font-12">Add</span></v-btn
          >
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    role: {},
  }),
  methods: {
    async addNewRoleHandler() {
      const { data } = await this.$axios.post(`roles`, this.role);
      if (data) {
        this.$store.dispatch("showSnack", {
          text: "Role Created Successfully",
          color: "success",
        });
        this.$emit('fetchData');
        this.$emit('cancel');
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
  },
};
</script>

<style></style>
