<template>
  <div class="">
    <div class="d-flex aling-center justify-space-between px-3 py-4 b-bottom">
      <span>Select Permissions for {{ role.name }}</span>
      <v-icon @click="$emit('cancel')">mdi-close</v-icon>
    </div>
    <div class="px-4">
      <v-switch
        v-model="selectAll"
        label="Select All"
        @change="switchHandler"
      ></v-switch>
    </div>
    <v-data-table
      :headers="headers"
      :items="permissions"
      :items-per-page="20"
      hide-default-footer
      item-key="_id"
      class="users-table elevation-0 mt-4 mb-5"
    >
      <!-- read -->
      <template v-slot:item.read="{ item }">
        <v-checkbox
          v-model="item.read"
          :hide-details="true"
          @change="togglePermission($event, 'read', item)"
        ></v-checkbox>
      </template>

      <!-- read -->
      <template v-slot:item.add="{ item }">
        <v-checkbox
          v-model="item.add"
          :hide-details="true"
          @change="togglePermission($event, 'add', item)"
        ></v-checkbox>
      </template>

      <!-- read -->
      <template v-slot:item.edit="{ item }">
        <v-checkbox
          v-model="item.edit"
          :hide-details="true"
          @change="togglePermission($event, 'edit', item)"
        ></v-checkbox>
      </template>

      <!-- read -->
      <template v-slot:item.delete="{ item }">
        <v-checkbox
          v-model="item.delete"
          :hide-details="true"
          @change="togglePermission($event, 'delete', item)"
        ></v-checkbox>
      </template>
    </v-data-table>
    <div class="px-5">
      <div class="divider-dark mb-4"></div>
    </div>
    <div class="d-flex align-center justify-space-between px-5 pb-3">
      <v-btn elevation="1" @click="$emit('cancel')"
        ><span class="font-12">Cancel</span></v-btn
      >
      <v-btn
        elevation="1"
        color="darkblue"
        dark
        @click="editRolePermissionsHandler"
        ><span class="font-12">Submit</span></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    role: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    headers: [
      { text: "Section", value: "section", width: "40%" },
      { text: "Read", value: "read", width: "10%" },
      { text: "Add", value: "add", width: "10%" },
      { text: "Edit", value: "edit", width: "10%" },
      { text: "Delete", value: "delete", width: "10%" },
    ],
    permissions: [
      {
        section: "Experiences",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Miniguides",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Villages",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Hosts",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Collections",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Users",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Coupons",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Categories",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Filters",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Stats",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Notifications",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Bookings",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Deleted Experiences",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
      {
        section: "Deleted Attractions",
        read: false,
        add: false,
        edit: false,
        delete: false,
      },
    ],
    selectAll: false,
  }),
  methods: {
    switchHandler(event) {
      this.permissions.forEach((perStatic) => {
        perStatic.read = event;
        perStatic.edit = event;
        perStatic.delete = event;
        perStatic.add = event;
      });
    },
    // async togglePermission(event, type, item) {
    async togglePermission() {
      // to delete all permissions ids
      // this.permissions.forEach(async item => {
      //   await this.$axios.delete(`roles/permission/${item.readid}`);
      //   await this.$axios.delete(`roles/permission/${item.editid}`);
      //   await this.$axios.delete(`roles/permission/${item.deleteid}`);
      //   await this.$axios.delete(`roles/permission/${item.addid}`);
      // })
      // if (event) {
      //   await this.$axios.post("roles/permission", {
      //     name: item.section + ", " + type,
      //   });
      // } else {
      //   await this.$axios.delete(`roles/permission/${item[type + "id"]}`);
      // }
      // await this.fetchPermissions();
    },
    async editRolePermissionsHandler() {
      let readId = "readid";
      let editId = "editid";
      let addId = "addid";
      let deleteId = "deleteid";

      let permissionsIDs = [];
      this.permissions.forEach((per) => {
        if (per[readId] && per["read"]) {
          permissionsIDs.push(per[readId]);
        }
        if (per[editId] && per["edit"]) {
          permissionsIDs.push(per[editId]);
        }
        if (per[addId] && per["add"]) {
          permissionsIDs.push(per[addId]);
        }
        if (per[deleteId] && per["delete"]) {
          permissionsIDs.push(per[deleteId]);
        }
      });
      let { data } = await this.$axios.put(`roles/${this.role._id}`, {
        permissions: permissionsIDs,
      });
      if (data) {
        this.$store.dispatch("showSnack", {
          text: "Permission Edited Successfully",
          color: "success",
        });
        this.$emit("fetchData");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
    async fetchPermissions() {
      let readId = "readid";
      let editId = "editid";
      let addId = "addid";
      let deleteId = "deleteid";

      // get all permissions to set the ids
      let { data } = await this.$axios("roles/permission/all");
      if (data) {
        this.permissions.forEach((perStatic) => {
          data.permissions.forEach((perData) => {
            if (perData.name.split(",")[0] == perStatic.section) {
              perStatic[perData.name.split(", ")[1] + "id"] = perData._id;
              // perStatic[perData.name.split(", ")[1]] = true;
            }
          });
        });

        // filter permission by host/admin
        this.permissions.forEach((perStatic) => {
          this.role.permissions.forEach((perData) => {
            if (perStatic[readId] == perData._id)
              perStatic[perData.name.split(", ")[1]] = true;
            if (perStatic[editId] == perData._id)
              perStatic[perData.name.split(", ")[1]] = true;
            if (perStatic[addId] == perData._id)
              perStatic[perData.name.split(", ")[1]] = true;
            if (perStatic[deleteId] == perData._id)
              perStatic[perData.name.split(", ")[1]] = true;
          });
        });
      }
    },
  },
  async created() {
    await this.fetchPermissions();
    let allSelected = true
    this.permissions.forEach((perStatic) => {
      if (
        !perStatic.read ||
        !perStatic.edit ||
        !perStatic.delete ||
        !perStatic.add
      ) {
        allSelected = false;
      }
    });
    this.selectAll = allSelected
  },
};
</script>

<style></style>
