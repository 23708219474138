<template>
  <div class="users px-6 py-5 mb-4" v-if="userRole == 'admin'">
    <div class="d-flex align-center justify-space-between">
      <span>Roles</span>
      <v-btn
        color="darkblue"
        dark
        style="font-size: 13px"
        @click="roleDialog = true"
        ><b> New Role</b></v-btn
      >
    </div>
    <div class="mb-10">
      <!-- table -->
      <v-data-table
        :headers="headers"
        :items="roles"
        :items-per-page="20"
        item-key="_id"
        class="users-table elevation-0 mt-4 mb-5"
      >
        <!-- actions -->
        <template v-slot:item.actions="{ item }">
          <div>
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2" v-bind="attrs" v-on="on"
                  >mdi-dots-horizontal</v-icon
                >
              </template>
              <v-list class="py-0 skyblue">
                <div
                  class="menu-item py-2 px-2 cursor-pointer"
                  @click="showPermissions(item)"
                >
                  <span class="font-14">Add Permissions</span>
                </div>
                <div class="divider-light"></div>

                <div
                  class="menu-item py-2 px-2 cursor-pointer"
                  @click="deleteConfirm(item)"
                >
                  <span class="font-14">Delete Role</span>
                </div>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </div>

    <!-- add new role -->
    <v-dialog v-model="roleDialog" content-class="white" max-width="500">
      <add-new-role
        @cancel="roleDialog = false"
        @fetchData="fetchRoles"
      ></add-new-role>
    </v-dialog>

    <!-- add permissions -->
    <v-dialog
      v-model="permissionDialog"
      :key="permissionDialog"
      content-class="white"
      max-width="700"
    >
      <add-permissions
        @cancel="permissionDialog = false"
        @fetchData="[fetchRoles(), permissionDialog = false]"
        :role="selectedItem"
      ></add-permissions>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
import AddNewRole from "@/components/dialogs/NewRole";
import AddPermissions from "@/components/dialogs/Permissions";
export default {
  components: {
    AddNewRole,
    AddPermissions,
  },
  data: () => ({
    headers: [
      { text: "Role Name", value: "name", width: "35%" },
      { text: "Actions", value: "actions", width: "10%" },
    ],
    roles: [],
    roleDialog: false,

    deleteDialog: false,
    selectedItem: "",

    permissionDialog: false,
  }),
  computed:{
    userRole(){
      return localStorage.getItem('role')
    }
  },
  methods: {
    deleteConfirm(item) {
      this.deleteDialog = true;
      this.selectedItem = item;
    },
    async deleteHandler() {
      const res = await this.$axios.delete(`/roles/${this.selectedItem._id}`);

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success",
        });
        this.deleteDialog = false;
        await this.fetchRoles();
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error",
        });
      }
    },
    showPermissions(item) {
      this.permissionDialog = true;
      this.selectedItem = item;
    },
    async fetchRoles() {
      let { data } = await this.$axios.get(`roles`);
      if (data) {
        this.roles = data.roles;
      }
    },
  },
  async created() {
    await this.fetchRoles();
  },
  // beforeRouteEnter(_, _2, next) {
  //   next((vm) => {
  //     if (localStorage.getItem("role") != "admin") {
  //       vm.$router.replace("/");
  //     }else{
  //       next()
  //     }
  //   });
  // },  
};
</script>

<style lang="scss">
.users {
  overflow-y: auto;
  height: 100vh;
  background: #fbfbfd;

  .users-table {
    background: #eaecee;
  }

  .mdi-arrow-up {
    display: none;
  }
}
</style>
